/**
 * 做到后台时动态获取
 */
export default {
  header: {
    menuList: ["Company", "Solutions", "Capabilities", "Connect"],
  },
  footer: {
    menuList: [
      {
        title: "Company",
        children: [
          { title: "About us" },
          { title: "History" },
          { title: "ESG" },
          // { title: "Resources" },
        ],
      },
      {
        title: "Solutions",
        children: [
          { title: "Products" },
          { title: "Automotive" },
          { title: "Consumer Electronics" },
          { title: "New Energy" },
          { title: "Home Appliances" },
        ],
      },
      {
        title: "Capabilities",
        children: [{ title: "Our Strength" }, { title: "Quality Control" }],
      },
      {
        title: "Contact",
        children: [
          { title: "Life At CMR" },
          { title: "Career" },
          { title: "Contact Us" },
        ],
      },
    ],
    copyright: "Copyright © 2023 CMR. All rights reserved.",
    contact: "Connect & Drive with Magnetics",
    privacy: "Privacy Policy",
    use: "Terms & Conditions",
    Number: "0571-64266030",
    location:
      "No. 1, Xiacheng Road, Tonglu County, Hangzhou City, Zhejiang Province (Postcode: 311500)",
  },
};
