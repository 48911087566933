
(function (doc, win) {
  var docEl = doc.documentElement,
    resizeEvt = "orientationchange" in window ? "orientationchange" : "resize",
    recalc = function () {
      var clientWidth = docEl.clientWidth;

      if (!clientWidth) return;
      if (clientWidth <= 760) {
        docEl.style.fontSize = 760 / 20 + "px";
        window.localStorage.setItem("screenType","mobile")
        return;
      }
      window.localStorage.setItem("screenType","computer")
      docEl.style.fontSize = clientWidth / 20 + "px";
      
      //   console.log(docEl.style.fontSize);
    };

  if (!doc.addEventListener) return;

  win.addEventListener(resizeEvt, recalc, false);

  doc.addEventListener("DOMContentLoaded", recalc, false);
})(document, window);
