export default {
  header: {
    menuList: ["公司介绍", "产品应用", "技术能力", "联系我们"],
  },
  footer: {
    menuList: [
      {
        title: "公司介绍",
        children: [
          { title: "关于我们" },
          { title: "公司历程" },
          { title: "ESG" },
          // { title: "创新资源" },
        ],
      },
      {
        title: "行业领域",
        children: [
          { title: "产品信息" },
          { title: "汽车和电动机" },
          { title: "消费电子" },
          { title: "新能源" },
          { title: "家电和应用工具" },
        ],
      },
      {
        title: "技术能力",
        children: [{ title: "我们的优势" }, { title: "质量管理" }],
      },
      {
        title: "联系我们",
        children: [
          { title: "企业文化" },
          { title: "职业发展" },
          { title: "联系我们" },
        ],
      },
    ],
    copyright: " © 2023杭州斯莫尔磁性材料有限公司。保留所有权利。",
    contact: "开始了解磁性材料",
    privacy: "隐私政策",
    use: "使用条款",
    Number: "0571-64266030",
    location: "浙江省杭州市桐庐县下城路1号(邮编：311500)",
  },
};
