import Vue from "vue";
import App from "./App.vue";
import router from "./router";
//语言切换文件
import i18n from "./i18n";
import "@/assets/css/global.less";
import "./assets/icons/index.js";
//适配js
import "@/utils/flexable.js";
//swiper的样式文件
import 'swiper/css/swiper.css'
import tool from "@/utils/tools.js";

Vue.use(tool);
//动画映入
Vue.config.productionTip = false;
new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
