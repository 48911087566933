import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
// 修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/",
    name: "layout",
    component: () => import("@/components/layout/index.vue"),
    redirect: "",
    children: [
      {
        /**
         * 首页页面的主体内容
         */
        path: "",
        component: () => import("@/views/home/index.vue"),
      },
      {
        /**
         * 首页页面的主体内容
         */
        path: "home",
        redirect: "/",
      },
      {
        /**
         * 公司页面
         */
        path: "company",
        component: () => import("@/views/company/index.vue"),
      },
      {
        path: "solutions",
        component: () => import("@/views/solutions/index.vue"),
      },
      {
        path: "applications",
        name: "applications",
        component: () => import("@/views/applications/index.vue"),
      },
      {
        path: "capabilities",
        component: () => import("@/views/capabilities/index.vue"),
      },
      {
        path: "contact",
        component: () => import("@/views/contact/index.vue"),
      },
      {
        path: "careers",
        component: () => import("@/views/careers/index.vue"),
      },
      {
        path: "jobapply/:jobid",
        component: () => import("@/views/jobapply/index.vue"),
      },
      {
        path: "blog/:bid",
        component: () => import("@/views/blog/index.vue"),
      },
      {
        path: "privacy",
        component: () => import("@/views/privacy/index.vue"),
      },
      {
        path: "resourse",
        component: () => import("@/views/resourse/index.vue"),
      },
      {
        path: "termsConditions",
        component: () => import("@/views/termsConditions/index.vue"),
      },
    ],
  },
  {
    path: "/submitSuccess",
    component: () => import("@/views/thanks/index.vue"),
  },
  {
    path: "/mapTest",
    component: () => import("@/components/map/index.vue"),
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  routes
});

router.beforeEach((to, from, next) => {
  if (to.query.md) {
    next();
    return;
  }
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  next();
});
router.afterEach((to, from, next) => {
  document
    .querySelector("body")
    .setAttribute("style", "overflow: auto !important;");
});
export default router;
