import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "./lang";
import { setItem, getItem } from "@/utils/localStorage.js";
Vue.use(VueI18n);

//判断当前浏览器使用的是什么语言
function navigatorLanguage() {
  let navigatorLanguage = (
    navigator.language || navigator.browserLanguage
  ).toLowerCase();
  if (getItem("language")) {
    navigatorLanguage = getItem("language");
    if (navigatorLanguage == "zh-cn") {
      document.getElementsByTagName("html")[0].style.fontFamily = "cmr_song";
    } else {
      document.getElementsByTagName("html")[0].style.fontFamily =
        "cmr_gothic";
    }
  } else {
    setItem("language", navigatorLanguage);
    if (navigatorLanguage == "zh-cn") {
      document.getElementsByTagName("html")[0].style.fontFamily = "cmr_song";
    } else {
      document.getElementsByTagName("html")[0].style.fontFamily =
        "cmr_gothic";
    }
  }
  let lang = "en";
  switch (navigatorLanguage) {
    case "zh-cn":
      lang = "zh-cn";
      document.querySelector("html").lang = "zh-cn";
      break;
    case "en":
      document.querySelector("html").lang = "en";
      lang = "en";
      break;
    default:
      document.querySelector("html").lang = "en";
      lang = "en";
      break;
  }
  return lang;
}

export default new VueI18n({
  locale: navigatorLanguage(),
  messages,
});
