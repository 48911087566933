<template>
    <svg :class="svgClass" aria-hidden="true">
      <use :xlink:href="iconName"/>
    </svg>
</template>
   
  <script>
    export default{
      name: 'SvgIcon',
      props: {
        iconClass: {
          type: String,
          required: true
        },
        className: {
          type: String,
          default: ''
        }
      },
      computed: {
        iconName() {
          return `#icon-${this.iconClass}`
        },
        svgClass() {
          if (this.className) {
            return 'svg-icon ' + this.className
          } else {
            return 'svg-icon'
          }
        }
      }
    }
  </script>
   
  <style scoped lang="less">
    .svg-icon {
      width: 1em;
      height: 1em;
      overflow: hidden;
      fill: currentColor;
    }
    
    .search{
      width: 0.3rem;
      height: 0.3rem;
    }
    .middle{
      width: 0.4rem;
      height: 0.4rem;
      margin-right: 0.2rem;
    }
    @media screen and (max-width: 760px){
      .a{
        width: 2.5rem;
        height: 1.5rem;
      }
    }
    @keyframes a  {
      
    }
  </style>