<template>
  <div id="app">
    <router-view v-if="isRresh"></router-view>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isRresh: true,
    };
  },
  provide() {
    return {
      refresh: this.refresh,
    };
  },
  methods: {
    refresh() {
      this.isRresh = false;
      this.$nextTick(() => {
        this.isRresh = true;
      });
    },
  },
  mounted(){
  }
};
</script>
<style lang="less" scoped>

</style>
